<template>
  <app-view>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary mt-2 mb-50">
      {{ $t('pages.plots-crop-types.name') }}
    </h1>
    <h4>{{ $t('pages.plots-crop-types.add.title') }}</h4>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="loader">
        <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-1"
        >
          <b-row>
            <b-col
              class="mb-1"
            >
              <div
                v-if="loader"
                class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  :label="$t('therms.loading')"
                />
              </div>
              <template v-else>
                <validation-observer
                  ref="addForm"
                  #default="{invalid}"
                >
                  <b-form
                    class="animate__animated"
                    :class="{'animate__shakeX':error}"
                    @submit.prevent="submit"
                  >
                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.crop')+' : '"
                      label-for="crop"
                    >
                      <b-form-input
                        id="crop"
                        size="lg"
                        :disabled="true"
                        :value="crop?.name"
                      />
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.crop-type')+' : '"
                      label-for="cropType"
                    >
                      <b-form-input
                        id="name"
                        size="lg"
                        :disabled="true"
                        :value="cropType?.name"
                      />
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.plot')+' : '"
                      label-for="plot_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="plot_id"
                        vid="plot_id"
                      >
                        <b-form-select
                          id="plot_id"
                          v-model="plot_id"
                          size="lg"
                          :options="plotOptions"
                        />
                      </validation-provider>
                    </b-form-group>

                    <div class="mt-2 pt-75">
                      <touch-button
                        size="sm"
                        :disabled="invalid || error"
                        :disable="invalid || error"
                      >
                        {{ $t('therms.save') }}
                      </touch-button>
                    </div>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
          </b-row>

        </div>
      </template>
    </div>
  </app-view>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { required, max, min } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Dialog } from '@capacitor/dialog'

export default {
  components: {
    NavButtonBar,
    NavButton,
    TouchButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,

      // data
      crop: null,
      cropType: null,
      plot_id: null,

      // validation rules
      required,
      max,
      min,
    }
  },
  computed: {
    ...mapGetters({
      has: 'plots/has',
      hierarchicalList: 'plots/getHierarchicalList',
    }),
    /**
     * @returns {Array<Plot>}
     */
    plotOptions() {
      const options = [{
        value: null,
        text: this.$t('pages.plots-crop-types.add.form.plot.default'),
        disabled: true,
      }]

      if (this.has
          && this.crop !== null
          && this.cropType !== null) {
        /**
         * @param {String} prefix
         * @param {Plot} plot
         */
        const addToOptions = (plot, prefix = '') => {
          const name = `${prefix} ${plot.name} ${plot.name !== plot.abbreviation ? `(${plot.abbreviation})` : ''}`.trim()

          options.push({
            value: plot.id,
            text: name,
            disabled: this.cropType?.plots_id.includes(plot.id),
          })

          if (addToOptions !== null && plot.subplots !== undefined && plot.subplots.length >= 1) {
            plot.subplots.forEach(e => addToOptions(e, `${name} > `.trim()))
          }
        }

        this.hierarchicalList
          .forEach(e => addToOptions(e))
      }

      return options
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 800)
    },
  },
  async mounted() {
    this.error = false
    await this.load()
  },
  methods: {
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async load(force = false) {
      this.loader = true
      try {
        if (force) {
          this.crop = null
          this.cropType = null
        }

        // eslint-disable-next-line radix
        this.crop = await this.$store.dispatch('crops/find', { id: parseInt(this.$router.currentRoute.params?.cropId) })
        // eslint-disable-next-line radix,prefer-destructuring
        this.cropType = this.crop.types.filter(ct => ct.id === parseInt(this.$router.currentRoute.params?.cropTypeId))[0]
      } catch (e) {
        this.error = true
        throw e
      } finally {
        this.loader = false
      }
    },
    async submit() {
      try {
        this.loader = true
        const result = await this.$store.dispatch('crops/addPlotOnCropType', {
          cropId: this.crop.id,
          cropTypeId: this.cropType.id,
          plotId: this.plot_id,
        })

        if (!result) {
          this.error = true
          await Dialog.alert({
            title: this.$t('therms.error').toString(),
            message: this.$t('actions.plot-crop-assign-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })
          this.loader = false
          return
        }

        await this.$router.back()
      } catch (err) {
        this.error = true
        throw err
      } finally {
        this.loader = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
